//import LocomotiveScroll from 'locomotive-scroll';
//import { gsap } from "gsap";

import AOS from 'aos' ;
import 'aos/dist/aos.css';
//import 'flowbite';

/*
const scroll = new LocomotiveScroll({

el:document.querySelector('[data-scroll-container]'),
smooth : true

});*/

AOS.init();

let toggleBtn = document.querySelector("#navbar-toggle");
let collapse = document.querySelector("#navbar-collapse");

toggleBtn.onclick = () => {
  collapse.classList.toggle("hidden");
  collapse.classList.toggle("flex");
};


const button = document.querySelector('#menu-button');
const menu = document.querySelector('#menu');


AOS.init({
  duration:1000,
  offset:5,
  delay:10,
});
AOS.refresh();




window.addEventListener('load', AOS.refresh)


button.addEventListener('click', () => {
  menu.classList.toggle('hidden');
});

if (document.readyState == 'complete') 
{
    AOS.refresh();
}